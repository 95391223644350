.teaser {
    display: block;
    width: 300px;
    height: 200px;
}

.directions li {
    display: inline-block;
    margin: 5px;
}

.correct {
    color: green;
}

.incorrect {
    color: red;
}

.photos {
    display: none;
}

header h1 {
    text-align: center;
}

header {
    margin-bottom: 15px;
}

.user-data label {
    width: 115px;
    display: inline-block;
}

.choose-date {
    margin-top: 10px;
}

.hidden {
    display: none;
}
.step3 {
    text-align: center;
}
.data-result {
    font-weight: bold;
}